import React from "react"
import { H1 } from "../../styled/typography.styled"
import { StyledFlexContainer, StyledH2 } from "../resume/ResumeMain"
import { FlexContainer } from "../../styled/layout.styled"
import ContactDetails from "./ContactDetails"
import ContactMe from "./ContactMe"
import Map from "./Map"

const ContactMain = () => {
  return (
    <>
      <StyledFlexContainer flexWrap="wrap">
        <H1>Contact</H1>
        <StyledH2>Get in Touch</StyledH2>
      </StyledFlexContainer>
      <Map />
      <FlexContainer
        width="100%"
        padding="50px 0 50px  0"
        flexWrap="wrap"
        justifyContent="center"
      >
        <ContactMe />
      </FlexContainer>
      <ContactDetails />
    </>
  )
}

export default ContactMain
