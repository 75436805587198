import React from "react"
import styled from "@emotion/styled"
import { FlexContainer } from "../../styled/layout.styled"

const StyledFlexContainer = styled(FlexContainer)`
  border: 3px solid #333;
  border-radius: 5px;
  margin: 80px 0 80px 0;
  width: 100%;
  transition: all 0.3s ease-in;
  &:hover {
    border: 3px solid #007ced;
  }
`

const Map = () => {
  return (
    <StyledFlexContainer>
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            width="100%"
            height="200"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=armenia%20yerevan%20kentron%20republic%20square&t=&z=11&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            title="location"
          />
        </div>
      </div>
    </StyledFlexContainer>
  )
}

export default Map
