import React from "react"
import { FlexContainer, FlexItem } from "../../styled/layout.styled"
// import { CONTACT_DETAILS } from "../../constants/data.constants"
import styled from "@emotion/styled"
import Phone from "../../assets/svg/smartphone.svg"
import Mail from "../../assets/svg/mail.svg"
import Pin from "../../assets/svg/pin.svg"

const StyledH4 = styled.h4`
  font-size: 16px;
  color: #f5f5f5;
  font-weight: 600;
  font-style: normal;
  a {
    color: #f5f5f5;
    text-decoration: none;
  }
`

const ContactDetails = () => {
  return (
    <FlexContainer
      mobileFlexDirection="column"
      mobileAlignItems="center"
      flexDirection="row"
      justifyContent="space-evenly"
      padding="100px 0 0 0"
      mobilePadding="0 0 0 0"
    >
      <FlexContainer alignItems="center">
        <FlexItem margin="0 5px 0 0">
          <Phone />
        </FlexItem>
        <StyledH4>
          <a href="tel:+374 41 121 085">+374 41 121 085</a>
        </StyledH4>
      </FlexContainer>
      <FlexContainer alignItems="center">
        <FlexItem margin="0 5px 0 0">
          <Mail />
        </FlexItem>
        <StyledH4>
          <a
            href="mailto:sakooghly@gmail.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            sakooghly@gmail.com
          </a>
        </StyledH4>
      </FlexContainer>
      <FlexContainer alignItems="center">
        <FlexItem margin="0 5px 0 0">
          <Pin />
        </FlexItem>
        <StyledH4>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.google.com/maps/place/Republic+Square/@40.1776986,44.5104624,17z/data=!4m5!3m4!1s0x406abcfa28499691:0x2bde14d127166c74!8m2!3d40.1776929!4d44.5126534"
          >
            Armenia, Yerevan
          </a>
        </StyledH4>
      </FlexContainer>
    </FlexContainer>
  )
}

export default ContactDetails
