import React from "react"
import SEO from "../components/seo"
import LayoutRoot from "../components/LayoutRoot"
import Layout from "../components/layout"
import ContactMain from "../components/contact/ContactMain"

const ContactPage = () => {
  return (
    <>
      <SEO title="Contact" />
      <LayoutRoot>
        <Layout>
          <ContactMain />
        </Layout>
      </LayoutRoot>
    </>
  )
}

export default ContactPage
