import React, { memo, useEffect, useState } from "react"
import { FlexContainer } from "../../styled/layout.styled"
import Input from "../reuseAble/Input"
import Button from "../reuseAble/Button"
import styled from "@emotion/styled"
import SectionTopic from "../reuseAble/SectionTopic"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers"
import Alert from "../reuseAble/Alert"

const StyledForm = styled.form`
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  @media only screen and (max-width: 970px) {
    width: 60%;
    padding: 0 0 28px 0;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    padding: 0 0 28px 0;
  }
`
const StyledFlexContainer = styled(FlexContainer)`
  position: relative;
`

const ContactMe = () => {
  const [alert, setAlert] = useState(false)
  const [timer, setTimer] = useState(false)

  const schema = yup.object().shape({
    name: yup.string().required().min(8),
    email: yup.string().email().required(),
    subject: yup.string().required().min(6),
    message: yup.string().required().min(10),
  })

  const { register, handleSubmit, errors, reset, formState } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  })

  const { isValid } = formState
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  useEffect(() => {
    setTimeout(() => {
      setAlert(false)
    }, 3000)
  }, [timer])

  const onSubmit = data => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...data }),
    })
      .then(() => {
        reset({})
        setAlert(true)
        setTimer(prevState => !prevState)
      })
      .catch(error => console.log(error))
  }

  return (
    <>
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        name="contact"
        netlify
        data-netlify
        netlify-honeypot="bot-field"
      >
        <SectionTopic topic="How Can I Help You ?" />
        <FlexContainer
          mobileFlexDirection="column"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
          padding="0 0 23px 0"
        >
          <StyledFlexContainer
            width="49%"
            mobileWidth="100%"
            mobilePadding=" 0 0 23px 0"
          >
            <Input
              name="name"
              id="name"
              placeHolder="Full Name"
              type="text"
              width="100%"
              error={errors?.name}
              errorMessage={errors?.name?.message}
              ref={register}
            />
          </StyledFlexContainer>

          <FlexContainer
            width="49%"
            justifyContent="flex-end"
            mobileWidth="100%"
          >
            <Input
              name="email"
              placeHolder="Email Address"
              type="text"
              width="100%"
              ref={register}
              error={errors?.email}
              errorMessage={errors?.email?.message}
            />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer width="100%" padding="0 0 23px 0">
          <Input
            name="subject"
            placeHolder="Subject"
            type="text"
            width="100%"
            ref={register}
            error={errors?.subject}
            errorMessage={errors?.subject?.message}
          />
        </FlexContainer>
        <FlexContainer width="100%" padding="0 0 23px 0">
          <Input
            name="message"
            placeHolder="Message"
            type="text"
            width="100%"
            textArea={true}
            ref={register}
            error={errors?.message}
            errorMessage={errors?.message?.message}
          />
        </FlexContainer>
        <FlexContainer width="100%" mobileWidth="100%" justifyContent="center">
          <Button
            disabled={!isValid}
            minWidth="100%"
            mobileWidth="200px"
            type="submit"
            margin="10px 0 0 0"
            width="250px"
          >
            Send
          </Button>
        </FlexContainer>
      </StyledForm>
      <Alert alert={alert} message={"your message was sent successfully"} />
    </>
  )
}

export default memo(ContactMe)
